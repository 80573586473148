import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  connect() {
    this.element.addEventListener("trix-focus", this.insertPlaceholder.bind(this))
    this.element.addEventListener("trix-blur", this.removePlaceholder.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("trix-focus", this.insertPlaceholder.bind(this))
    this.element.removeEventListener("trix-blur", this.removePlaceholder.bind(this))
  }

  insertPlaceholder(event) {
    const editor = event.srcElement.editor
    if (editor.getDocument().getLength() <= 1) {
      editor.recordUndoEntry("Contenu par défaut")
      editor.insertHTML(this.sourceHTML)
    }
  }

  removePlaceholder(event) {
    const editor = event.srcElement.editor
    const undos = editor.undoManager.undoEntries
    const content = this.element.querySelector("input[type=hidden]")?.value
    if (undos.length == 1 && undos[0].description == "Contenu par défaut") {
      editor.loadHTML("")
      return
    }
    // If the user made edits, compare the HTML
    if (this.normalizeHTML(content) === this.normalizeHTML(this.sourceHTML)) {
      editor.loadHTML("")
    }
  }

  normalizeHTML(html) {
    return html?.replace(/&nbsp;/g, " ")
  }

  get sourceHTML() {
    return this.sourceTarget.innerHTML.trim().match(/^<div class="trix-content">\s?(.*)\s?<\/div>$/)[1]?.trim()
  }
}


